import * as React from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardActionArea,
  Container,
  NoSsr,
  IconButton,
  Rating,
  Skeleton
} from "@mui/material";
import {useRouter} from "next/router";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FavoriteIcon from "@mui/icons-material/Favorite";
import useEffect from "../../hooks/useEffectOne";

function CardStoreContent(props) {
  const router = useRouter();
  const [value, setValue] = React.useState(5);

  useEffect(() => {});
  const redirectStore = () => {
    router.push(`${router.locale}/places/${props.props.data.clinic_id}`);
  };
  return (
    <React.Fragment>
      {/* Header unit */}
      <NoSsr>
        <Card style={{marginRight: 20, marginBottom: 20, width: 345}}>
          {props.props.data && (
            <CardActionArea onClick={() => redirectStore()}>
              <div>
                <div
                  style={{
                    backgroundImage: `url(${props.props.data.clinic_profile_picture_url})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    height: "170px",
                    padding: 10,
                    display: "flex",
                    flexWrap: "wrap"
                  }}
                >
                  <div style={{flexGrow: 1}} />
                  <IconButton
                    color="primary"
                    aria-label="picture"
                    style={{
                      backgroundColor: "#5EB594",
                      height: "40px"
                    }}
                  >
                    <FavoriteIcon style={{color: "white"}} />
                  </IconButton>
                </div>
              </div>

              <CardContent>
                <div style={{display: "flex", flexDirection: "row"}}>
                  <Avatar
                    alt="Remy Sharp"
                    src={
                      props.props.data.logoImage
                        ? props.props.data.logoImage
                        : "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2Flogoshop1.png?alt=media&token=34caf33e-01f0-4162-9223-869910541ffe"
                    }
                    sx={{width: 56, height: 56}}
                  />
                  <div style={{marginLeft: 15, marginTop: "-15px"}}>
                    <h4>{props.props.data.clinic_name}</h4>
                    <div style={{marginTop: "-12px"}}>
                      <span
                        style={{
                          color: "#5EB594",
                          marginRight: 10,
                          fontSize: "12px"
                        }}
                      >
                        5.0
                      </span>
                      <Rating
                        name="read-only"
                        value={value}
                        readOnly
                        size="small"
                        style={{
                          color: "#3E5A50",
                          paddingTop: "-10px"
                        }}
                      />
                      <span
                        style={{
                          color: "#ADADAD",
                          marginLeft: 10
                        }}
                      >
                        {"(120)"}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: 15,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}
                >
                  <div style={{marginTop: 5}}>
                    <LocationOnIcon style={{paddingTop: 10}} />
                    <span>{`${props.props.data.district},${props.props.data.province}`}</span>
                  </div>
                  {props.props.data.isShowPrice && (
                    <h4>{`${props.props.data.price} THB`}</h4>
                  )}
                </div>
              </CardContent>
            </CardActionArea>
          )}
        </Card>
      </NoSsr>
    </React.Fragment>
  );
}
export default function StoreCards(props) {
  return <CardStoreContent props={props} />;
}
