import * as React from "react";
import Image from "next/image";
import Axios from "axios";
import {
  Button,
  Container,
  Typography,
  Link,
  TextField,
  InputAdornment
} from "@mui/material";
import {useRouter} from "next/router";
import {useTranslation, Trans} from "next-i18next";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import {getCookie} from "cookies-next";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import {ScrollMenu, VisibilityContext} from "react-horizontal-scrolling-menu";
import useEffect from "../hooks/useEffectOne";
import styles from "../styles/Home.module.css";
import Footer from "./components/footer";
import Header from "./components/header";
import StoreCard from "./components/storeCard";
import ModalLogin from "./components/modalLogin";
import {useDispatch, useSelector} from "react-redux";
import {toggleModal, selectIsVisibleModal} from "../redux/reducers/authData";

function HomeContent() {
  const router = useRouter();
  const {t} = useTranslation("common");
  const dispatch = useDispatch();
  const isVisibleModal = useSelector(selectIsVisibleModal);
  const [carouselImage, setCarouselImage] = React.useState([
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2FBanner-Description-1.jpg?alt=media&token=42d8805c-676b-4d2f-ae12-fdea0f888b56"
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2FBanner-invite%20friend.jpg?alt=media&token=6afad4f1-e95b-4982-9d66-ca152c6f5973"
    }
  ]);
  const [categoryData, setCategoryData] = React.useState([
    {
      categoryName: "Body",
      image:
        "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2F1.png?alt=media&token=ed904856-0432-4ccd-8c1f-af7ae1474491"
    },
    {
      categoryName: "Head to Neck",
      image:
        "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2F2.png?alt=media&token=296301a3-84a4-413d-939e-8848daa83a69"
    },
    {
      categoryName: "Genitals/Intimate",
      image:
        "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2F3.png?alt=media&token=7030dddc-7c80-48c2-9043-273b6e65f176"
    },
    {
      categoryName: "Dental",
      image:
        "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2F4.png?alt=media&token=ee31d050-7855-40af-85f3-8c060d587a1d"
    },
    {
      categoryName: "Liposuction",
      image:
        "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2FFrame.png?alt=media&token=0c483d9c-7903-4417-8689-5055cdd97c94"
    },
    {
      categoryName: "Skin/Injection",
      image:
        "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2F6.png?alt=media&token=39afa3e6-b82a-469f-a46a-bfe80042b2b2"
    },
    {
      categoryName: "Hair",
      image:
        "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2F7.png?alt=media&token=046a1c70-ab23-44a4-b470-836f5c689397"
    }
  ]);
  const [bodyData, setBodyData] = React.useState();
  const [headToNeckData, setHeadToNeckData] = React.useState();
  const [genitalsData, setGenitalsData] = React.useState();
  const [liposuctionData, setLiposuctionData] = React.useState();
  const [skinData, setSkinData] = React.useState();
  const [hairData, setHairData] = React.useState();
  const [storeData, setStoreData] = React.useState([
    {
      storeName: "Thonglor Arena 10 Clinic",
      coverImage:
        "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2Fcovershop1.png?alt=media&token=de27fad8-09f0-452e-a80f-c04bf89b9735",
      logoImage:
        "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2Flogoshop1.png?alt=media&token=34caf33e-01f0-4162-9223-869910541ffe",
      location: "Thonglor soi 10, Bangkok",
      price: "10,000"
    },
    {
      storeName: "Meso Whitening (1 cc.)",
      coverImage:
        "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2Fcovershop2.png?alt=media&token=d0b0694b-cfff-4c3c-ba8a-dd63bc7303ca",
      logoImage:
        "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2Flogoshop2.png?alt=media&token=a3f10fa1-0bf4-4689-9ace-6368a1b4aa9d",
      location: "Pathumwan",
      price: "599"
    },
    {
      storeName: "Nabota - Forehead",
      coverImage:
        "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2Fcovershop3.png?alt=media&token=529d1d82-8f69-469f-add8-1877e2f1020b",
      logoImage:
        "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2Flogoshop3.png?alt=media&token=73c8c99e-fe8d-4214-b239-969792b7405c",
      location: "Huai Khwang",
      price: "1,990"
    },
    {
      storeName: "HIFEM - EMSCULPT",
      coverImage:
        "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2Fcovershop4.png?alt=media&token=d98f6623-425e-41b6-9d7a-1983c167b81b",
      logoImage:
        "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/Booclii%2Flogoshop4.png?alt=media&token=fc7a6711-b6d5-41bf-9e41-1a0f616079e7",
      location: "Wang THonglang",
      price: "10,500"
    }
  ]);
  const isMobile = typeof window !== "undefined" && window.innerWidth <= 767.98;
  const [name, setName] = React.useState("");
  const [selectLanguage, setSlectLanguage] = React.useState({
    icon:
      "https://upload.wikimedia.org/wikipedia/commons/8/87/US_flag_49_stars.svg",
    name: "EN"
  });

  useEffect(() => {
    getAuthenticate();
    getClinics();
  });
  const getAuthenticate = () => {
    const user = getCookie("name");

    setName(user === undefined ? "" : user);
  };
  const handleClickClose = () => {
    dispatch(toggleModal());
  };
  const getClinics = async () => {
    try {
      const response = await Axios.get(
        "https://booclii-api.tiravat.com/api/v0.0.1/clinics"
      );
      console.warn("response", response);
      setStoreData(response.data.result);
    } catch (e) {
      console.log("err");
    }
  };
  return (
    <React.Fragment>
      {/* Header unit */}
      <Header />

      {/* Body unit */}

      <div>
        <div style={{height: "50%", width: "100%"}}>
          <Carousel showArrows={true} showThumbs={false} dynamicHeight>
            {carouselImage.map((item, i) => (
              <div key={i}>
                <img src={item.image} height={500} alt={`image${i + 1}`} />
              </div>
            ))}
          </Carousel>
        </div>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            marginTop: "-13px"
          }}
        >
          <TextField
            className={styles.textfieldSearch}
            placeholder={t("textfieldSearch.search1")}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <TextField
            className={styles.textfieldSearch}
            placeholder={t("textfieldSearch.search2")}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon />
                </InputAdornment>
              )
            }}
          />
          <Button
            variant="contained"
            size="large"
            className={styles.buttonSearch}
            style={{backgroundColor: "#3E5A50"}}
          >
            {t("buttonSearch")}
          </Button>
        </div>
        <div className={styles.categoryDiv}>
          {categoryData.map((item, i) => (
            <Link
              key={i}
              variant="button"
              href={`/?q=${item.categoryName}`}
              underline="none"
              color="rgba(0, 0, 0, 0.5)"
            >
              <div className={styles.categoryButton}>
                <div>
                  <img
                    src={item.image}
                    className={styles.categoryImages}
                    alt={`categoryImage${i + 1}`}
                  />
                </div>
                <span>{item.categoryName}</span>
              </div>
            </Link>
          ))}
        </div>
        <div>
          <Container
            maxWidth="lg"
            sx={{
              mt: 8,
              mb: 8
            }}
          >
            {storeData && storeData.length > 0 ? (
              <ScrollMenu>
                {storeData.map((item, i) => (
                  <StoreCard data={item} key={i} />
                ))}
              </ScrollMenu>
            ): null}
          </Container>
        </div>
      </div>
      <ModalLogin isVisible={isVisibleModal} onClose={handleClickClose} />
      {/* Footer */}
      <Footer />

      {/* End footer */}
    </React.Fragment>
  );
}
export const getStaticProps = async ({locale}) => ({
  props: {
    ...(await serverSideTranslations(locale, ["common"]))
  }
});
export default function Home() {
  return <HomeContent />;
}
// {router.query.q === undefined ? (
//   storeData.map((item, i) => (
//     <div key={i}>
//       <div className={styles.bodyRow}>
//         <h3>{item.typeName}</h3>
//         {item.isSeeMore && (
//           <Link
//             href={`/#`}
//             underline="none"
//             color="rgba(0, 0, 0, 0.5)"
//           >
//             <h5>{"see more >"}</h5>
//           </Link>
//         )}
//       </div>
//
//       <ScrollMenu>
//         {item.store.map((item, i) => (
//           <StoreCard data={item} key={i} />
//         ))}
//       </ScrollMenu>
//     </div>
//   ))
// ) : router.query.q === "Body" ? (
//   bodyData.map((item, i) => (
//     <div key={i}>
//       <div className={styles.bodyRow}>
//         <h3>
//           <img
//             src={item.icon}
//             alt={`iconImage${i + 1}`}
//             style={{ marginRight: 20 }}
//           />
//           {item.typeName}
//         </h3>
//         {item.isSeeMore && (
//           <Link
//             href={`/#`}
//             underline="none"
//             color="rgba(0, 0, 0, 0.5)"
//           >
//             <h5>{"see more >"}</h5>
//           </Link>
//         )}
//       </div>
//
//       <ScrollMenu>
//         {item.store.map((item, i) => (
//           <StoreCard data={item} key={i} />
//         ))}
//       </ScrollMenu>
//     </div>
//   ))
// ) : router.query.q === "Head to Neck" ? (
//   headToNeckData.map((item, i) => (
//     <div key={i}>
//       <div className={styles.bodyRow}>
//         <h3>
//           <img
//             src={item.icon}
//             alt={`iconImage${i + 1}`}
//             style={{ marginRight: 20 }}
//           />
//           {item.typeName}
//         </h3>
//         {item.isSeeMore && (
//           <Link
//             href={`/#`}
//             underline="none"
//             color="rgba(0, 0, 0, 0.5)"
//           >
//             <h5>{"see more >"}</h5>
//           </Link>
//         )}
//       </div>
//
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         {item.store.map((item, i) => (
//           <StoreCard data={item} key={i} />
//         ))}
//       </div>
//     </div>
//   ))
// ) : router.query.q === "Genitals/Intimate" ? (
//   genitalsData.map((item, i) => (
//     <div key={i}>
//       <div className={styles.bodyRow}>
//         <h3>
//           <img
//             src={item.icon}
//             alt={`iconImage${i + 1}`}
//             style={{ marginRight: 20 }}
//           />
//           {item.typeName}
//         </h3>
//         {item.isSeeMore && (
//           <Link
//             href={`/#`}
//             underline="none"
//             color="rgba(0, 0, 0, 0.5)"
//           >
//             <h5>{"see more >"}</h5>
//           </Link>
//         )}
//       </div>
//
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         {item.store.map((item, i) => (
//           <StoreCard data={item} key={i} />
//         ))}
//       </div>
//     </div>
//   ))
// ) : router.query.q === "Liposuction" ? (
//   liposuctionData.map((item, i) => (
//     <div key={i}>
//       <div className={styles.bodyRow}>
//         <h3>
//           <img
//             src={item.icon}
//             alt={`iconImage${i + 1}`}
//             style={{ marginRight: 20 }}
//           />
//           {item.typeName}
//         </h3>
//         {item.isSeeMore && (
//           <Link
//             href={`/#`}
//             underline="none"
//             color="rgba(0, 0, 0, 0.5)"
//           >
//             <h5>{"see more >"}</h5>
//           </Link>
//         )}
//       </div>
//
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         {item.store.map((item, i) => (
//           <StoreCard data={item} key={i} />
//         ))}
//       </div>
//     </div>
//   ))
// ) : router.query.q === "Skin/Injection" ? (
//   skinData.map((item, i) => (
//     <div key={i}>
//       <div className={styles.bodyRow}>
//         <h3>
//           <img
//             src={item.icon}
//             alt={`iconImage${i + 1}`}
//             style={{ marginRight: 20 }}
//           />
//           {item.typeName}
//         </h3>
//         {item.isSeeMore && (
//           <Link
//             href={`/#`}
//             underline="none"
//             color="rgba(0, 0, 0, 0.5)"
//           >
//             <h5>{"see more >"}</h5>
//           </Link>
//         )}
//       </div>
//
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         {item.store.map((item, i) => (
//           <StoreCard data={item} key={i} />
//         ))}
//       </div>
//     </div>
//   ))
// ) : router.query.q === "Hair" ? (
//   hairData.map((item, i) => (
//     <div key={i}>
//       <div className={styles.bodyRow}>
//         <h3>
//           <img
//             src={item.icon}
//             alt={`iconImage${i + 1}`}
//             style={{ marginRight: 20 }}
//           />
//           {item.typeName}
//         </h3>
//         {item.isSeeMore && (
//           <Link
//             href={`/#`}
//             underline="none"
//             color="rgba(0, 0, 0, 0.5)"
//           >
//             <h5>{"see more >"}</h5>
//           </Link>
//         )}
//       </div>
//
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         {item.store.map((item, i) => (
//           <StoreCard data={item} key={i} />
//         ))}
//       </div>
//     </div>
//   ))
// ) : (
//   <div />
// )}
